<nav [class]="classPrefix('MainPod')">
  <section [class]="classPrefix('Pod0')">
    <logo-img-zero
    [class]="classPrefix('Pod0Img0')"
    ></logo-img-zero>
    <h1 [class]="classPrefix('Pod0Title0')">{{"global.title" | translate}}</h1>
  </section>
  <section [class]="classPrefix('Pod1')">
    <ul
    [class]="classPrefix('Pod1Item0')">
        <a
        (pointerenter)="toggleNav('open')"
        [class]="classPrefix('Pod1Link0')">{{"NavZero.mainOptions.0.0"| translate}}</a>
    </ul>
    <div
    (click)="toggleNav('close')"
    [ngStyle]="nav.style"
    (animationend)="nav.animationEnd($event)"
    [class]="nav.class">
      <ul [class]="classPrefix('Pod1Item2')" *ngFor="let group of navService.desktopNavZero">
        <li
        [id]="link.id"
        [class]="classPrefix('Pod1Link1')" *ngFor="let link of group.text">
          <a
          [routerLink]="link.routerLink"
          (click)="toggleNav('close',link.click)">
            {{link.text | translate}}
          </a>
          </li>
      </ul>
    </div>


  </section>
  <section [class]="classPrefix('Pod2')">
    <div
    *ngIf="!['survey'].includes(viewType)"
    [class]="classPrefix('Pod2Item0')">
      <div
      [class]="classPrefix('Pod2Item2')"
      (click)="toggleLangs()"
      >
        <i [class]="classPrefix('Pod2Icon1') + ' fa-solid fa-earth-africa'"></i>
        <p [class]="classPrefix('Pod2Text0')">{{utilService.currentLang | translate}}</p>
        <i
        [class]="classPrefix('Pod2Icon2') + ' fa-solid fa-chevron-down'"></i>
      </div>
      <wml-infinite-dropdown-zero
      *ngIf="langsDropdown.isPresent"
      [class]="classPrefix('Pod2Item1')"
      [props]="langsDropdown"></wml-infinite-dropdown-zero>

    </div>
    <wml-button-one
    *ngIf="!['legal','survey'].includes(viewType)"
    [props]="accountProfileBtn"></wml-button-one>
    <wml-button-one
    *ngIf="!['legal','survey'].includes(viewType)"
    [props]="takeSurveyBtn"></wml-button-one>
  </section>
  <section [class]="classPrefix('Pod3')">
    <i
    (click)="navService.mobileNavZero.open()"
    [class]="classPrefix('Pod3Icon0') + ' fa-solid fa-bars'"></i>
  </section>
</nav>
