{
  "global": {
    "title": "Eneobia",
    "website": "",
    "logoImgAlt": "Logo Img",
    "errorRequired": "This field is required",
    "errorEmail":"Please provide a valid email",
    "errrorMin0": "At least one value must be provided",
    "avatarImgAlt": "Avatar Img",
    "wmlChipsplaceholder": "Type your value then press enter to see it appear",
    "wmlChipsuserInputsAriaLabel": "User Inputs",
    "wmlChipsremoveChipAriaLabel": "remove",
    "fillOutForm": "Please fill out all required fields",
    "loginAgain": "Please login again to continue using your account",
    "logoutError": "There was an issue while trying to log you out of your account please try again or contact support if the issue persists",
    "overlayLoading": "Loading ...",
    "siteUnderConstruction": "Site Under Construction",
    "formSubmitSuccess": "Form Submitted Sucessfully",
    "systemError": "An error has occured please try again later or contact support if the issue persists",
    "serviceUnavailableError": "The resource you requested is not available due to system maintenance, please try again in a few minutes",
    "submitBtn": "Submit",
    "comingSoon": "This feature is not availble yet, please try again later",
    "na": "N\\A",
    "countries": [
      "US"
    ],
    "add": "Add",
    "remove": "Remove",
    "view":"View",
    "of":"of",
    "saveBtn": "Saved!",
    "saveBtnNotSaved": "Save?",
    "applyChanges": "Apply Changes",
    "back":"Back",
    "link":"Link",
    "download":"Download",
    "progress":"Progress",
    "email":"Email",
    "states": [
      "AL",
      "AK",
      "AZ",
      "AR",
      "CA",
      "CO",
      "CT",
      "DE",
      "FL",
      "GA",
      "HI",
      "ID",
      "IL",
      "IN",
      "IA",
      "KS",
      "KY",
      "LA",
      "ME",
      "MD",
      "MA",
      "MI",
      "MN",
      "MS",
      "MO",
      "MT",
      "NE",
      "NV",
      "NH",
      "NJ",
      "NM",
      "NY",
      "NC",
      "ND",
      "OH",
      "OK",
      "OR",
      "PA",
      "RI",
      "SC",
      "SD",
      "TN",
      "TX",
      "UT",
      "VT",
      "VA",
      "WA",
      "WV",
      "WI",
      "WY"
    ],
    "langAbbreviations": [
      "EN",
      "AM",
      "BN",
      "DE",
      "ES",
      "FR",
      "HI",
      "MS",
      "SW",
      "UK",
      "ZH"
    ],
    "wmlSelect": {
      "select": "Please Select"
    },
    "WMLNotifyOne": {
      "purchaseMoreCredits":"It looks like you are out of credits for this action please either purchase more credits or upgrade your subscription to complete this action",
      "reconnectToPlatform": "It looks like there was an issue. Please try to reconnect to a video platform and storage provider to resolve the issue. If the issue persists please contact support",
      "disabledAccount":"Your account has been disabled. Please reach out to support for further assistance",
      "ensureEnoughStorage": "NOTICE !!!!. MAKE SURE you have enough storage space with your storage provider",
      "providerExists":"It may be that you have created an account with a different provider. Please make sure to sign in with that provider"
    },
    "wmlChipsZero":{
      "placeholder":"Type your value then press enter to see it appear",
      "clearBtn":"Clear"
    },
    "wmlFileUpload":{
      "dragDropText" :"Drag and drop the file(s) here or click on \"Browse Files\".",
      "browseFileText": "Browse Files"
    },
    "registerAgreement": [
      "By using our services, you acknowledge that you have read, understood, and agree to our",
      "Terms of Service",
      "and ",
      "Privacy Policy"
    ]
  },
  "mobile": {
    "googlePlayStoreAlt": "Get it on Google Play",
    "appleAppStoreAlt":"Download on the App Store"
  },
  "ConfirmDialogZero": {
    "title": "WARNING! This action cannot be undone. Are you sure you wish to proceed",
    "subtitle":"",
    "options": [
      "Yes",
      "No"
    ]
  },
  "SignUpPage": {
    "title": "Welcome to Eneobia!",
    "haveAccount": "Already have an account?",
    "signInLink": "SIGN IN",
    "signUpBtn": "REGISTER",
    "or": "OR",
    "WMLNotifyOne": {
      "emailAlreadyInUse": "This email is already in use, please sign in or contact us if you believe there is an issue",
      "tosMustBeAccepted": "You must agree to the Terms of Service and Privacy Policy in order to create an account."
    },

    "socialMedia": [
      "Sign up with google",
      "Sign up with facebook",
      "Sign up with twitter",
      "Sign up with apple"
    ],
    "mainForm": {
      "emailField": {
        "label": "Email",
        "error": {
          "email": "A valid email is required"
        }
      },
      "passField": {
        "label": "Password",
        "error": {
          "compare": "Password and confirm password don't match!",
          "minlength": "Please enter a password with a length greater than 6 charaters"
        }
      },
      "confirmPassField": {
        "label": "Confirm Password",
        "error": {
          "compare": "Password and confirm password don't match!"
        }
      }
    }
  },
  "SignInPage": {
    "title": "Welcome Back!",
    "haveAccount": "Don't have an account?",
    "signUpLink": " SIGN UP",
    "signInBtn": "LOGIN",
    "forgotPassLink": "RESET PASSWORD?",
    "or": "OR",
    "WMLNotifyOne": {
      "alreadyLoggedIn": "Your are already logged in, if you meant to login/register another account logout first",
      "tosMustBeAccepted": "You must agree to the Terms of Service and Privacy Policy in order to log in to your account.",
      "incorrectCredentialsOrUserNotFound": "The username or password you have entered is incorrect or your account may not exist. Please create a new account , reset your password or contact support if the issue persists"
    },

    "socialMedia": [
      "Sign in with google",
      "Sign in with facebook",
      "Sign in with twitter",
      "Sign in with apple"
    ],
    "mainForm": {
      "emailField": {
        "label": "Email",
        "error": {
          "email": "Please provide a valid email"
        }
      },
      "passField": {
        "label": "Password",
        "error": {
          "minlength": "Please enter a password with a length greater than 6 charaters"
        }
      }
    }
  },
  "ForgotPassPage": {
    "title": "Forgot Your Password?",
    "WMLNotifyOne":{
      "sendPassRecoveryMsg":"If  you have an account with us refer to your email for instructions on resetting your password. Or else you may need to create a new account"
    },
    "backBtn": "Back",
    "prompt": "Please enter your email and click submit. You will receive a email with instructions on how to reset your password",
    "recoveryCodeBtn": "Send Password Recovery Email",
    "mainForm": {
      "emailField": {
        "label": "Email",
        "error": {
          "email": "Please provide a valid email"
        }
      }
    }
  },
  "AccountsService": {
    "userName": "Default Username",
    "donate0": "Donate",
    "signUp0": "Sign Up",
    "login0": "Log In",
    "logout0": "Log Out",
    "profileBtn0": "Profile",
    "profileURLAlt": "Profile Image",
    "WMLNotifyOne": {
      "emailRequiredToCreateAnAccount":"It seems you may have omitted providing your during the account creation process. An email is necessary for the core workings of our app such as disputes, notifications and asset management. We cannot accommodate accounts without emails at this time.",
      "corruptedAccount": "It seems an account was created for you but it is corrupted, Please try again later or contact customer support for asistance, if the error continues, please refer to our legal section to export and delete your data and create a new account",
      "loggedOutSuccess": "Logged out sucessfully",
      "loggedOutError": "There was an error while trying to log out please try again later or contact support if the issue persists",
      "changePasswordWithoutCurrentPassword": "Your account has a password. Please provide your accounts current password or reset your password if you forgot it",
      "signInAgainToChangePassword":"It has been some time since you last signed in order to confirm its you sign out, sign in again and change your password"
    }
  },
  "nav": {
    "pageInfo": {
      "default": {
        "title": "Eneobia",
        "description": "Manage Your Content On All Platforms At the click of a button.",
        "keywords": "Media management platform, Eneobia, Content creators, Bulk editing digital assets, External storage integration, file transfer, video data editor"
      },
      "landing": {
        "title": "Eneobia",
        "description": "Manage Your Content On All Platforms At the click of a button.",
    "keywords": "Media management platform, Eneobia, Content creators, Bulk editing, Storage integration, file transfer, video data editor"
      },
      "contact": {
        "title": "Eneobia Contact",
        "description": "Get in touch with the Eneobia team.",
    "keywords": "Eneobia, contact, support, help, customer service, assistance, inquiry"
      },
      "about": {
        "title": "Eneobia About",
        "description": "Learn more about Eneobia and our mission.",
    "keywords": "Eneobia, about, company, mission, values, background, story"
      },
      "store": {
        "title": "Eneobia Store",
        "description": "Explore the Eneobia store for exclusive content and products.",
    "keywords": "Eneobia, store, products, exclusive, shop, merchandise, sales"
      },
      "overview": {
        "title": "Eneobia Account Profile",
        "description": "Manage your Eneobia account settings.",
    "keywords": "Eneobia, account overview, activities, settings, profile, account management, dashboard"
      },
      "hub": {
        "title": "Eneobia Connection Hub",
        "description": "Connect with others through the Eneobia Connection Hub.",
    "keywords": "Eneobia, connection hub, platforms, Google Drive, YouTube, Video Platform, Storage Platform"
      },
      "manage": {
        "title": "Eneobia Video Manager",
        "description": "Manage your videos with the Eneobia Video Manager.",
    "keywords": "Eneobia, video manager, video editing, media management, content control, video organization"
      },
      "sign-up": {
        "title": "Eneobia Sign Up",
        "description": "Sign up for an Eneobia account.",
    "keywords": "Eneobia, sign up, register, create account, join, new user, registration"
      },
      "sign-in": {
        "title": "Eneobia Sign In",
        "description": "Sign in to your Eneobia account.",
    "keywords": "Eneobia, sign in, login, access account, authentication, user entry"
      },
      "terms-and-conditions": {
        "title": "Eneobia Terms and Conditions",
        "description": "Understand the terms and conditions of using Eneobia.",
    "keywords": "Eneobia, terms and conditions, legal, policy, agreement, rules, regulations"
      },
      "privacy-policy": {
        "title": "Eneobia Privacy Policy",
        "description": "Understand how Eneobia handles your privacy.",
    "keywords": "Eneobia, privacy policy, data protection, personal information, security, confidentiality"
      },
      "survey": {
        "title": "Eneobia Survey",
        "description": "Participate in the Eneobia survey.",
    "keywords": "Eneobia, survey, feedback, opinion, participation, questionnaire"
      },
      "forgot-pass": {
        "title": "Eneobia Forgot Password",
        "description": "Reset your Eneobia account password.",
    "keywords": "Eneobia, forgot password, reset password, account recovery, password assistance"
      },
      "data-editor": {
        "title": "Eneobia Video Data Editor",
        "description": "Edit your video data with Eneobia's editor.",
    "keywords": "Eneobia, video data editor, video editing, metadata, content modification, data management"
      },
      "upload": {
        "title": "Eneobia Upload",
        "description": "Upload your media files to your platforms. Transfer files to other devices",
    "keywords": "Eneobia, upload, media files, file transfer, large file transfer, content upload"
      },
      "site-offline": {
        "title": "Eneobia Site Offline",
        "description": "The Eneobia site is currently offline.",
    "keywords": "Eneobia, site offline, maintenance, downtime, unavailable, temporary suspension"
      },
      "friends": {
        "title": "Eneobia Friends",
        "description": "Manage your friends on Eneobia.",
    "keywords": "Eneobia, friends, social, connections, network, relationships"
      },
      "billing": {
        "title": "Eneobia Billing",
        "description": "Manage your billing information with Eneobia.",
    "keywords": "Eneobia, billing, payment, purchases, subscriptions, financial management"
      },
      "guide": {
        "title": "How to Use Eneobia",
        "description": "Learn how to use Eneobia with our comprehensive guide.",
    "keywords": "Eneobia, guide, how to use, tutorial, help, instructions"
      },
      "verify-email": {
        "title": "Eneobia Verify Email",
        "description": "Verify your email address for your Eneobia account.",
    "keywords": "Eneobia, verify email, email verification, account security, confirmation"
      }
    }
  },
  "siteOffine": {
    "title": "Site Offline",
    "subtitle": "The site is currently unavailable due to scheduled maintenance, Please try again later"
  },
  "notFound": {
    "lines": [
      "404",
      "Whoops! This is not what you were looking for",
      "But you just found our logo, thanks. Click on the logo to go back to the ",
      "home page."
    ],
    "someImgAlt": "Resume"
  },
  "NavZero": {
    "logoBackgroundAlt":"Drawer Logo Background",
    "myAccount": "MY ACCOUNT",
    "takeSurvey": "TAKE SURVEY!",
    "mainOptions": [
      [
        "NAVIGATION"
      ]
    ],
    "mainPopupOptions": [
      [
        "VIDEO MANAGER",
        "CONNECTION HUB",
        "PROGRESS TRACKER",
        "VIDEO DATA EDITOR",
        "UPLOAD & TRANSFER"
      ],
      [
        "TERMS OF SERVICE",
        "PRIVACY POLICY",
        "HOW TO GUIDE"
      ]
    ],
    "mobileOptions": [
      [
        "LANGUAGES"
      ],
      [
        "HOW TO GUIDE"
      ],
      [
        "TAKE SURVEY!"
      ],
      [
        "VIDEO MANAGER"
      ],
      [
        "CONNECTION HUB"
      ],
      [
        "PROGRESS TRACKER"
      ],
      [
        "VIDEO DATA EDITOR"
      ],
      [
        "UPLOAD & TRANSFER"
      ],
      [
        "TERMS OF SERVICE"
      ],
      [
        "PRIVACY POLICY"
      ],
      [
        "PROFILE"
      ]
    ]
  },
  "CartZero": {
    "title": "YOUR CART",
    "removeItem": "Remove Item",
    "total": "Total:",
    "quantity": "Quantity:",
    "checkout": "Submit Your Order",
    "WMLNotifyOne": {
      "noItemsInCart": "Please add items to your cart before continuing"
    }
  },
  "InfoZero": {
    "text0": "WELCOME TO",
    "text1": "THE BUTTER",
    "text2": "Pellentesque mi purus, eleifend sedt commodo vel, sagittis elts vesti ulum dui sagittis mlste sagittis elts. Exercitation photo booth that is end stumptown tote bag Banksy, elit small batch freegan sed craft a beer elit seitan exercitation, photo booth et kale chips hillwave deep laborum mlste sagittis sunt culpa only oficia."
  },
  "HomePage": {},
  "CardZero": {
    "rating": "Rating"
  },
  "CardOne":{
    "title":"Section"
  },
  "LandingLayout": {
    "title": "ENEOBIA",
    "subtitle1": "Manage Your Content On All Platforms",
    "subtitle2": "At the click of a button",
    "signUpBtn": "Get Started",
    "signInBtn": "Sign In",
    "descriptions":[
      {
        "title": "Seamless Media Management and Synchronization",
        "description": "Eneobia is designed to empower content creators by providing a robust platform for managing and editing digital assets in bulk. With the ability to perform batch operations like PREPEND, APPEND, OVERWRITE, INSERT, and REPLACE on media titles, descriptions, tags, and thumbnails, creators can streamline their workflow and focus on producing high-quality content."
      },
      {
        "title": "Seamless Integration with Google Drive",
        "description": "Eneobia connects seamlessly with external storage providers, allowing users to download, edit, and manage their media files effortlessly. After completing bulk edits, users receive a direct download link to access their content, ensuring their media is always within reach."
      },
      {
        "title": "Powerful Bulk Editing Capabilities",
        "description": "Eneobia’s bulk editing feature is a game-changer for content creators who manage large volumes of media. With just a few clicks, users can update metadata across all of their content, enhancing efficiency and consistency in their content presentation."
      },
      {
        "title": "Cross-Device File Transfer",
        "description": "Eneobia enables hassle-free file transfers between devices, regardless of file type or size. Whether you’re on your computer or mobile device, you can easily send and receive files, ensuring your media is always available where you need it."
      },
      {
        "title": "ENEOBIA: Bulk Digital Content Management",
        "description": "Eneobia is a content management platform designed for creators and businesses to seamlessly manage and synchronize their media across multiple platforms. Whether you're a photographer, a content creator, or a marketer, Eneobia helps you upload, organize, and distribute your digital assets effortlessly, all from a single, intuitive interface. Click on and move your mouse to each of the image to learn about the different features of the app."
      },
      {
        "title": "YouTube Integration",
        "description": "Currently, Eneobia supports bulk media managemenet from YouTube, providing creators with a streamlined way to manage their content."
      },
      {
        "title": "Secure Media Backup: Protect Your Originals Before Editing",
        "description": "Eneobia provides a robust backup capability that ensures the safety and integrity of your media before any edits are made. Before you initiate bulk edits on your digital assets, Eneobia offers the option to create a full backup of your media directly to your connected storage provider, such as Google Drive or Dropbox. This backup process is seamless and ensures that all your original files are securely stored and easily accessible."
      },
      {
        "title": "Save and Reuse Your Preferences",
        "description": "These saved settings are securely stored with your connected storage provider, not locally, so you can access and apply them across different sessions or devices. This capability significantly enhances productivity by allowing you to quickly reapply your preferred configurations, eliminating the need to manually reset them for each new batch of edits. Whether you're working on one project or managing multiple, Eneobia's ability to save and recall your data editor settings ensures your workflow remains efficient, consistent, and tailored to your specific needs."
      },
      {
        "title": "Comprehensive Video Management Dashboard",
        "description": "Eneobia's dashboard provides a centralized hub where content creators can manage, edit, and organize their videos with ease. The intuitive interface allows users to sort and filter content by platforms, channels, and playlists, making it simple to navigate through large libraries of media. With bulk action capabilities and seamless integration with external storage providers, the dashboard streamlines the entire process. The dashboard is designed for efficiency, enabling users to quickly locate and manage their media assets, apply edits, and organize content across multiple platforms, all within a single, unified interface."
      }

    ]
  },
  "NavOne": {
    "mobileOptions": [
      [
        "LANGUAGES"
      ]
    ],
    "routes": [
      [
        "DASHBOARD"
      ],
      [
        "PROFILE"
      ],
      [
        "FRIENDS"
      ],
      [
        "BILLING"
      ],
      [
        "SIGN OUT"
      ]
    ]
  },
  "AccountOverviewPage": {
    "WMLNotifyOne": {
      "pushNotificationsDenied":"Push notifications denied. Check your browser settings to allow push notifications and try again once you have updated your settings",
      "cannotRevokePushNotificationThisWay":"You must go into your browser settings to revoke the push notification refresh the page once done",
      "accountExportSucessTitle":"Account Data Exported Sucessfully",
      "accountExportSucessBody":"Click to view",
      "accountExportSucessInfo":"To view your export open our notification from your notification bar",
      "accountNotifyInfoUpdateSuccess":"Account notification info updated sucessfully",
      "accountProfileInfoUpdateSuccess":"Account profile info updated sucessfully",
      "accountPasswordChangedSucess":"Account password changed sucessfully",
      "accountDeletedSuccess": "Account deleted sucessfully",
      "accountDataDeletionRequestSuccess": "Account data deletion request submitted sucessfully",
      "accountDataDeletionRequestFailed":"It may be that you have already submitted a data deletion request. We are doing our best to review your request and send a response. If it has been a few months without a response please contact support"
    },
    "mainForm": {
      "submitBtn": "Update Profile Info",
      "displayNameField": {
        "label": "Display Name",
        "error": {
        }
      },
      "emailField": {
        "label": "Email",
        "error": {
        }
      },
      "emailVerifiedField": {
        "label": "Email Verified",
        "error": {}
      },
      "phoneNumberField": {
        "label": "Phone: (US Only)",
        "error": {
          "numeric":"Please only enter numbers no dashes spaces or underscores"
        }
      },
      "startWithGuideField": {
        "label": "Start With How To Guide",
        "error": {}
      }
    },
    "notifyForm": {
      "submitBtn": "Update Notification Info",
      "emailMarketingConsentField": {
        "label": "Email Notifications",
        "error": {}
      },
      "smsMarketingConsentField": {
        "label": "SMS Notifications",
        "error": {}
      },
      "pushNotificationsField": {
        "label": "Push Notifications",
        "error": {}
      },
      "incomingVideosNotificationsField": {
        "label": "Incoming Videos Notifications",
        "error": {}
      }
    },
    "changePassForm": {
      "currentPassField": {
        "label": "Current Password",
        "error": {}
      },
      "passField": {
        "label": "New Password",
        "error": {
          "compare": "Password and confirm password don't match!",
          "minlength": "Please enter a password with a length greater than 6 charaters"
        }
      },
      "confirmPassField": {
        "label": "Confirm Password",
        "error": {
          "compare": "Password and confirm password don't match!"
        }
      }
    },
    "currentSub": "Current Subscription",
    "noSubs": "You have no subscriptions at this time",
    "addSub": "Add Subscription",
    "yourData": "Your Data",
    "deleteAccountData":{
      "requestWillBeReviewed":"Your data deletion request will be reviewed and you will be emailed the approval or denial along with any explanation if deemed necessary. If approved your data will be deleted anywhere between the approval date and a month after"
    },
    "deleteAccount":{
      "forAMonth":"Your account will be disabled for at most 1 month with a grace period of at least 7 days before permanent deletion. During this time if you log into your account your account will be restored. Remember to sign out of all devices"
    },
    "yourDataOptions": [
      {
        "title": "Export Your Data",
        "btn": "Export",
        "desc": "Easily access and manage your personal content with our \"Export Your Data\" feature. Your data will be available for download in JSON format, providing a structured snapshot of your interactions."
      },
      {
        "title": "Delete Your Data",
        "btn": "Delete Data",
        "desc": "The Delete Your Data allows you to restore your account to its inital state. (Except for data saved to your storage provider) Useful to unlink all external accounts, cancel all subscriptions, reset all preferences and reset all account data "
      },
      {
        "title": "Delete Your Account",
        "btn": "Delete Account",
        "desc": "The Delete Your Account option puts you in charge of your information. Permanently remove your content from our platform and systems."
      }
    ]
  },
  "FriendsZeroPage": {
    "title": "Your Friends",
    "WMLNotifyOne":{
      "friendsLimit":"You have reached the maximum limit for adding friends this limit may changed or be removed in the future. You will have to manage for the time being",
      "updatedSucessfully":"Friends Updated Sucessfully",
      "errorRetrievingFriends":"An error occured while trying to retrieve your friends please contact support or try again later if the issue persists"
    },
    "mainForm": {
      "friendsField": {
        "label": "Friends",
        "error": {}
      }
    }
  },
  "BillingZeroPage": {
    "title": "Billing",
    "limitsBtn":"Limitations",
    "changeSubBtn": "Change Subscription",
    "cancelSubBtn": "Cancel Subscription",
    "purchaseNowBtn": "Purchase Now",
    "WMLNotifyOne":{
      "subscriptionBillSuccess":"You have been sucessfully billed for your subscription. Reload the application when you are ready to use your new credits",
      "subscriptionBillFailed":"There was an error while billing your subscription. Please cancel and purchase a new subscription or contact support for any additional issues",
      "errorProcessingOrder":"Your order was paid for but there was an error processing your order please urgently contact support right away!"
    },
    "subs": {
      "title": "Subscription",
      "noSubs": "You have no subscriptions at this time",
      "addSub": "Add Subscription"
    },
    "credits": {
      "title": "Credits",
      "downloads": "Download Credits",
      "backups": "Backup Credits",
      "bulkEdits": "Bulk Edit Credits",
      "fileTransfers": "File Transfer Credits",
      "channel": "Channel Credits"
    },
    "currentSub": {
      "parts": [
        "Confirmation Number :",
        "Start Date",
        "Next Bill Date"
      ]
    },
    "creditsForm": {
      "downloadField": {
        "label": "Download",
        "error": {}
      },
      "backupField": {
        "label": "Backup",
        "error": {}
      },
      "bulkEditField": {
        "label": "Bulk Edit",
        "error": {}
      },
      "fileTransferField": {
        "label": "File Transfer",
        "error": {}
      },
      "channelsField": {
        "label": "Channels",
        "error": {}
      }
    }
  },
  "LimitsZero":{
    "title":"Current Limitations",
    "rules":[
      "Youtube videos must be public",
      "If you have multiple channels under one account the app only loads one channel for subsequent channels you would have to add the videos yourself"
    ]
  },
  "VerifiedZero": {
    "button": "Verify Email",
    "msg": "Verified"
  },
  "PricingCardZero": {
    "name": "GOLD",
    "intervals": {
      "day": "DAILY",
      "week": "WEEKLY",
      "month": "MONTHLY",
      "year": "YEARLY"
    },
    "selectBtn": "Select"
  },
  "ConnectionHubPage": {
    "WMLNotifyOne": {
      "storageServiceRevokedSuccess": "Storage service disconnected sucessfully",
      "storageServiceRevokedFailed": "Failed to disconnect storage service. Please try again later or contact support if the issue persists",
      "platformRevokedSuccess": "Platform disconnected sucessfully",
      "platformRevokedFailed": "Failed to disconnect platform. Please try again later or contact support if the issue persists",
      "platformConnectFailed": "Failed to connect to the platform. Please try again later or contact support if the issue persists"
    },
    "platformsTitle": "Platforms",
    "platformsList": [
      "Youtube"
    ],
    "connectBtn": "Connect",
    "disconnectBtn": "Disconnect",
    "connected": "Connected!",
    "disconnected": "Connect Now!",
    "platformImgAlt": "Platform Background Image",
    "storageTitle": "Storage Providers"
  },
  "VideoManagerPage": {
    "dragMe": "Drag me if you want to reorder the filters",
    "WMLNotifyOne": {
      "retrievingVideoData": "The process of the first-time retrieval of you video information will take a few minutes to a few hours and it may fail. As a remedy, if you need to run jobs right away click add videos to start loading your most needed videos. In the mean time feel free to explore the rest of the platform! ",
      "connectToAPlatform": "Please connect to a video and storage platform to start managing your videos"
    },
    "videoDataEditorBtn":"Video Data Editor",
    "addVideoBtn": {
      "text": "Add Videos"
    },
    "search": {
      "title": "Search",
      "search": {
        "placeholder": "Search Videos"
      }
    },
    "sort":{
      "title":"Sort"
    },
    "platformsFilter": {
      "title": "Platforms"
    },
    "channelsFilter": {
      "title": "Channels"
    },
    "playlistsFilter": {
      "title": "Playlists"
    },
    "videoDataEditor":{
      "title":"Video Data Editor",
      "bulkSelectOptions":[
        "Select All","Unselect All"
      ]
    },
    "filterDrawer": "Search Filters",
    "noVideosBasedOnFilters": "No videos were found based on your selected filters. Please try again with different filters"
  },
  "VideosToEditWereChosenGuard": {
    "WMLNotifyOne": {
      "needToSelectVideos": "Please select one or more videos in order to open the video data editor"
    }
  },
  "SearchZero": {
    "placeholder": "Type in text to search",
    "searchBtn": {
      "iconAlt": "Search Icon"
    }
  },
  "SortZero":{
    "fields":[
      "Platforms","Channels","Playlists","Title"
    ],
    "direction": [
      "↑",
      "↓"
    ]
  },
  "VideoDataEditorPage": {
    "tags": "Tags",
    "desc": "Description",
    "backup":"Backup Before Changing",
    "updateVideosBtn":"Update Videos",
    "WMLNotifyOne":{
      "issuesWhileSaving":"There were some issues that occured while updating your account please try again later or contact support if the issue persists",
      "noThumbnailsSaved":"Just a reminder thunbnails are not saved you can only make updates with thumbnails"
    }
  },
  "VideoTileZero": {
    "WMLNotifyOne":{
      "deletedSucessfully":"Video deleted sucessfully",
      "deleteVideoPending":"This will delete the video from Eneobia not the platform the video is on"
    },
    "iframeTitle": "Video Player",
    "platform": "Platform",
    "channel": "Channel",
    "playlist": "Playlist",
    "video": "Video",
    "downloadBtn": "Download",
    "deleteBtn": "Delete",
    "editVideoDataForm": {
      "chosenField": {
      "label": "Chosen",
        "error": {}
      }
    }
  },

  "CurrentPageZero": {
    "text": "Page :"
  },
  "PrivacyPolicy": {
    "title": "Eneobia Privacy Policy",
    "sections": [
      "Effective: February 13, 2024",
      "Welcome to Eneobia. Your privacy is critically important to us. Eneobia is committed to protecting your personal information and your right to privacy.",
      "If you have any questions or concerns about our policy or our practices with regards to your personal information, please contact us at contact@windmillcode.com.",
      "When you visit our website and use our services, you trust us with your personal information. We take your privacy very seriously. In this privacy policy, we describe our privacy policy.",
      "We seek to explain to you in the clearest way possible what information we collect, how we use it, and what rights you have in relation to it.",
      "This privacy policy applies to all information collected through our website (such as https://eneobia.com), mobile application, and/or any related services, sales, marketing, or events (we refer to them collectively in this privacy policy as the 'Services').",
      "Please read this privacy policy carefully as it will help you make informed decisions about sharing your personal information with us.",
      "Information We Collect:",
      [
        "Personal Information You Disclose to Us",
        "We collect personal information that you voluntarily provide to us when expressing an interest in obtaining information about us or our products and services, when participating in activities on the Services, or otherwise contacting us."
      ],
      "The personal information that we collect depends on the context of your interactions with us and the Services, the choices you make, and the products and features you use.",
      [
        "The personal information we collect can include the following:",
        "Names",
        "Phone Numbers",
        "Email Addresses",
        "Mailing Addresses",
        "Job Titles",
        "Debit/Credit Card Numbers",
        "Usernames",
        "Passwords",
        "Contact Preferences",
        "Contact or Authentication Data",
        "Billing Addresses",
        "Sensitive Information"
      ],
      "We process your information for purposes based on legitimate business interests, the fulfillment of our contract with you, compliance with our legal obligations, and/or your consent.",
      "We use the information we collect or receive:",
      [
        "To facilitate account creation and logon process.",
        "To post testimonials.",
        "Request feedback.",
        "To enable user-to-user communications.",
        "To manage user accounts.",
        "To send administrative information to you.",
        "To protect our Services.",
        "To enforce our terms, conditions, and policies for business purposes, to comply with legal and regulatory requirements or in connection with our contract.",
        "To respond to legal requests and prevent harm."
      ],
      "Sharing Your Information:",
      [
        "We only share information with your consent, to comply with laws, to provide you with services, to protect your rights, or to fulfill business obligations."
      ],
      "We may process or share your data that we hold based on the following legal basis:",
      [
        "Consent: We may process your data if you have given us specific consent to use your personal information for a specific purpose.",
        "Legitimate Interests: We may process your data when it is reasonably necessary to achieve our legitimate business interests.",
        "Performance of a Contract: Where we have entered into a contract with you, we may process your personal information to fulfill the terms of our contract.",
        "Legal Obligations: We may disclose your information where we are legally required to do so in order to comply with applicable law, governmental requests, a judicial proceeding, court order, or legal process.",
        "Vital Interests: We may disclose your information where we believe it is necessary to investigate, prevent, or take action regarding potential violations of our policies, suspected fraud, situations involving potential threats to the safety of any person, and illegal activities, or as evidence in litigation in which we are involved."
      ],
      "Data Retention:",
      [
        "We will retain your personal information only for as long as is necessary for the purposes set out in this privacy policy unless a longer retention period is required or permitted by law (such as tax, accounting, or other legal requirements)."
      ],
      "Security of Your Information:",
      [
        "We use administrative, technical, and physical security measures to help protect your personal information. While we have taken reasonable steps to secure the personal information you provide to us, please be aware that despite our efforts, no security measures are perfect or impenetrable, and no method of data transmission can be guaranteed against any interception or other type of misuse."
      ],
      "Privacy Rights:",
      [
        "You may review, change, or terminate your account at any time.",
        "If you are a resident in the European Economic Area and you believe we are unlawfully processing your personal information, you also have the right to complain to your local data protection supervisory authority."
      ],
      "Updates to This Policy:",
      [
        "We will update this policy as necessary to stay compliant with relevant laws.",
        "We will notify you about significant changes by sending a notice to the primary email address specified in your account, by placing a prominent notice on our site, and/or by updating any privacy information."
      ],
      "Contact Us:",
      [
        "If you have questions or comments about this policy, you may email us at contact@windmillcode.com or by post to:",
        "Windmillcode",
        "2810 N Church St, Wilmington, DE 19802, PMB 380240"
      ]
    ]
  },
  "TermsAndConditions": {
    "title": "Terms and Conditions",
    "sections": [
      "Effective: February 13, 2024",
      "AGREEMENT TO OUR LEGAL TERMS",
      "We are Windmillcode (\"Company,\" \"we,\" \"us,\" \"our\"). We operate the video management platform Eneobia, targeted at content creators for uploading, downloading, and transferring videos in bulk, as well as generating shorts for direct upload to connected platforms or for download. This service is accessible through our website and is planned to be extended to mobile and desktop applications in the future.",
      "You can contact us via email at contact@windillcode.com or by mail at 2810 N Church St, Wilmington, DE 19802 PMB 380240.",
      "These Legal Terms form a legally binding agreement between you, whether personally or on behalf of an entity (\"you\"), and Windmillcode, regarding your access to and use of the Platform. By accessing the Platform, you confirm that you have read, understood, and agree to be bound by these Terms. If you do not agree with any part of these terms, you must discontinue use immediately.",
      "We will notify users about updates to these terms via email and platform announcements. Changes become effective upon posting or as otherwise notified.",
      "The Platform is intended for users who are at least 18 years old. Persons under this age are not permitted to use the Platform.",
      "1. DESCRIPTION OF THE PLATFORM",
      "Eneobia is designed for content creators to efficiently manage video content. It supports functionalities like bulk uploads, downloads, transfers, and the generation of shorts. Currently, Eneobia integrates with YouTube for bulk downloads, with plans to expand its features to include automatic short generation and additional download options. The platform stores video metadata on the user's device for publicly accessible videos only; it does not support private and unlisted videos within any subscription plan.",
      "2. ACCESS AND USE OF THE PLATFORM",
      "By accessing Eneobia, you agree to these Terms and Conditions and commit to comply with all applicable laws and regulations. You are responsible for maintaining the confidentiality of your account and password and for all activities that occur under your account.",
      "3. SUBSCRIPTION SERVICES",
      "Eneobia offers subscription services that automatically renew each month. By subscribing, you acknowledge and agree to these terms. The platform does not offer a free trial. Specific details regarding subscription fees and services will be provided during the subscription process.",
      "4. INTELLECTUAL PROPERTY RIGHTS",
      "Windmillcode owns or holds the licenses to all intellectual property rights in our Platform, including, but not limited to, all software, databases, website designs, audio, video, text, photographs, and graphics. The trademarks, service marks, and logos contained within the Platform are owned by us or licensed to us and are subject to copyright and other intellectual property rights under United States and international laws and conventions.",
      "5. USER CONDUCT",
      "The Platform may only be used for lawful purposes and in ways that comply with these Terms. Users are prohibited from violating any applicable laws and regulations in their jurisdiction while using the Platform.",
      "6. USER-GENERATED CONTENT",
      "Users are permitted to upload content, including videos and comments. By posting content to the Platform, users grant Windmillcode a non-exclusive, royalty-free license to use, modify, and display such content in relation to the provided services.",
      "7. THIRD-PARTY LINKS AND CONTENT",
      "The Platform may contain links to third-party websites not owned or controlled by Windmillcode. We assume no responsibility for the content, privacy policies, or practices of any third-party sites.",
      "8. DISCLAIMER OF WARRANTIES",
      "The Platform and all related services are provided on an \"AS IS\" and \"AS AVAILABLE\" basis without any warranties of any kind, either express or implied, including, but not limited to, the implied warranties of merchantability or fitness for a particular purpose.",
      "9. LIMITATION OF LIABILITY",
      "To the fullest extent permitted by law, Windmillcode shall not be liable for any indirect, incidental, special, consequential, or punitive damages resulting from your access to or use of, or inability to access or use, the Platform.",
      "10. INDEMNIFICATION",
      "You agree to defend, indemnify, and hold harmless Windmillcode, its affiliates, and their respective directors, officers, employees, and agents from and against all claims, damages, obligations, losses, liabilities, costs, debt, and expenses arising from your use of the Platform, violation of these Terms, or the posting of user-generated content on the Platform.",
      "11. MODIFICATION OF TERMS",
      "Windmillcode reserves the right, in its sole discretion, to modify or replace these Terms at any time. If a revision is material, we will provide at least 30 days notice before any new terms take effect. What constitutes a material change will be determined at our sole discretion.",
      "12. GOVERNING LAW",
      "These Terms shall be governed by and construed in accordance with the laws of the United States, without giving effect to any principles of conflicts of law.",
      "13. DISPUTE RESOLUTION",
      "In the event of a dispute, you are to contact Windmillcode's customer support team at contact@windmillcode.com for assistance. If resolution cannot be achieved, the dispute will be resolved through binding arbitration conducted by the American Arbitration Association (AAA) under its rules.",
      "14. SUBSCRIPTION CANCELLATION",
      "Users can cancel their subscriptions at any time by logging into their account and following the instructions provided within the account settings.",
      "15. PROHIBITED ACTIVITIES",
      "Users are prohibited from engaging in activities such as unauthorized advertising, sharing of illegal content, engaging in fraudulent activities, distributing malware, harassment or bullying, spamming, manipulating platform mechanics, engaging in illegal activities, and violating the terms and agreements of the platform.",
      "16. NOTIFICATIONS OF UPDATES",
      "Windmillcode will notify users about updates to these terms via email and platform announcements, ensuring users are always informed of any changes."
    ]
  },
  "JobsZero": {
    "WMLNotifyOne": {
      "cantSaveCertainJobs":"Remember UPDATE VIDEO and BACKUP PREVIOUS STATE jobs must come from the video data editor you can not save them as a new job in this panel",
      "jobInProgressCantAddVideo": "You cannot add a video to a job that is in progress. Please wait for the job to finish or create a new job to add videos to",
      "jobIsDeleted": "Job Deleted!. However you are not supposed to be able to run a deleted job. Please contact customer support for assistance",
      "mustAddVideosToJob": "Please add a video to this job",
      "mustSelectAStoragePlatform": "You must select a storage platform to start a download or AI Shorts job",
      "errorRetrievingJobs": "There was an error while trying to retrieve your jobs please try again later or contact support if the issue persists",
      "preventTheRunOfSucessfulJobsWithLinks":"This job seems to have succeeded and has a link. This needs to be modified in order to run again"

    },
    "noJobs": "No jobs found. Add a job to get started",
    "title": "Jobs",
    "addJobBtn": "Add Job",
    "clearFinishedAndFailedJobsBtn": "Clear Finished and Failed Jobs",
    "startJobBtn": "Start Job",
    "deleteJobBtn": "Delete Job"
  },
  "AiShortsJobZero": {
    "comingSoon": "This feature is not availble yet, please try again later"
  },
  "DownloadJobZero": {
    "noVideosInJob": "Please add a video to this job",
    "itemTitle": "Title - ",
    "itemFilesize": "File Size - ",
    "totalFilesize": "Total File Size: "
  },
  "UpdateVideosJobZero":{
    "thumbnailURL":"Link"
  },
  "JobTitleZero": {
    "selectJobBtnChosen": "Chosen!",
    "selectJobBtn": "Select",
    "mainForm": {
      "titleField": {
        "label": "Title",
        "options": [
          "Download Job",
          "AI Shorts Job",
          "Backup Editor Job",
          "Update Videos Job",
          "Transfer Local Files Job"
        ],
        "error": {}
      }
    }
  },
  "JobStatusZero": {
    "status": "Status:",
    "downloadURL": "Job URL:",
    "downloadURLValue": "Link",
    "downloadURLValueMissing": "Check in the Eneobia folder in your storage provider"
  },
  "ListJobs": {
    "status": {
      "NOT_STARTED": "NOT STARTED",
      "PENDING": "PENDING",
      "IN_PROGRESS": "IN PROGRESS",
      "SUCCESS": "SUCCESS",
      "FAILED": "FAILED",
      "CANCELED": "CANCELED",
      "RETRY": "RETRY",
      "ISSUES": "ISSUES"
    }
  },
  "AddNewVideoZero": {
    "title": "Please provide the video URLs",
    "mainForm": {
      "downloadURLField": {
        "label": "Video URL",
        "error": {}
      }
    }
  },
  "AddNewVideoOne": {
    "instructions":[
      "for youtube videos (not shorts) the URL must include youtube.com and watch?v= "
    ],
    "WMLNotifyOne":{
      "addVideoLimit":"You can only add 50 videos at a time",
      "addVideoJob":"Add Video Job In Progress. Reload the app if you do not see any changes",
      "addVideoJobError":"There was an error while trying to add a video. It may be you added a video that is not yours, which is not allowed. Please try again  or contact support if the issue persists"
    },
    "mainForm": {
      "downloadURLsField": {
        "label": "Video URL",
        "error": {}
      }
    }
  },
  "MultiInputOne": {
    "WMLNotifyOne":{
      "limit":"You cannot add any more entries"
    },
    "mainForm": {
      "valueField": {
        "label": "Value",
        "error": {}
      }
    }
  },
  "VideoDataEditorZero": {
    "WMLNotifyOne":{
      "noChangesInDataEditor":"There are no changes in the data editor you will need to click on any portion of the video info in this page to open the data editor to make changes"
    },
    "submit":"Preview Changes",
    "types": [
      "Title",
      "Tags",
      "Description",
      "Thumbnail"
    ],
    "section": [
      "Title",
      "Tags",
      "Description",
      "Thumbnail"
    ],
    "action": [
      "Prepend",
      "Append",
      "Overwrite",
      "Insert",
      "Replace"
    ],
    "replaceLabelText":[
      "replace from line",
      "replace from character",
      "replace to line",
      "replace to character"
    ],
    "insertAtLine":"Insert at line",
    "insertAtChar":"Insert at character",
    "mainForm": {
      "videosField": {
        "label": "Videos",
        "error": {}
      },
      "typeField": {
        "label": "Type",
        "error": {}
      },
      "actionField": {
        "label": "Action",
        "error": {
          "required":"Please select an action",
          "compound":"You must select 'Overwrite' when type is thumbnail"
        }
      },
      "insertField": {
        "label": "Insert At",
        "error":{
          "cannotBeGreaterThanMax":"Value cannot be greater than max"
        }
      },
      "replaceField": {
        "label": "Replace At",
        "error":{
          "invalidCoordinates":"From coordinates cannot be greater than To coordinates",
          "cannotBeGreaterThanMax":"Value cannot be greater than max"
        }
      },
      "contentField": {
        "label": "Content",
        "error": {
          "required":"Please provide content or else the videos cant be modified"
        }
      }
    }
  },
  "ContentEditorControlZero": {
    "mainForm": {
      "typeField": {
        "label": "Type",
        "error": {}
      },
      "titleField": {
        "label": "Title",
        "error": {
          "maxLength":"The length of a title cannot be more than 100 characters"
        }
      },
      "tagsField": {
        "label": "Tags",
        "error": {
          "maxItemLength":"The length of an individual tag cannot be more than 500 characters"
        }
      },
      "descriptionField": {
        "label": "Description",
        "error": {
          "maxLength":"The length of a description cannot be more than 5000 characters"
        }
      },
      "thumbnailField": {
        "label": "Thumbnail",
        "error": {
          "extension":"Only files with jpg jpeg and png are allowed",
          "fileSize":"The maximum file size is 2MB"
        }
      }
    }
  },
  "HcaptchaZero":{
    "WMLNotifyOne":{
      "failed":"It seems you have failed the challenge, please try again"
    }
  },
  "webrtcService":{
    "localFileTransfer":{
      "confirmDialog":"The ability to receive files from other users is only available on the IOS mobile app at this time. You must sign in into the app and ask the sender to send again. Would you like to go there now?"
    }
  },
  "UploadZeroPage": {
    "action": "Action",
    "transferBtn": "Send Files To Another User",
    "uploadBtn": "Upload To Platform",
    "localFileTransfer":{
      "confirmDialog":"A user wants to send you a file. Approve only if you trust the sender and are sure you will not get SCAMMED/HACKED. If an unknown sender contacts you, urgently contact support with the sender's email."
    },
    "mainForm": {
      "videosField": {
        "label": "Files",
        "error": {}
      }
    }
  },
  "TransferLocalFilesJobZero":{
    "client":{
      "title":"Client Type",
      "types":["Sender","Recipient"]
    },
    "estimatedTime":{
      "title":"Estimated Time",
      "days":"days",
      "hours":"hours",
      "minutes":"minutes",
      "seconds":"seconds"
    },
    "fileName":"File Name",
    "fileType":"File Type",
    "senders":"Senders",
    "recipients":"Recipients",
    "WMLNotifyOne":{
      "errorOnSendingFiles":"An error occured while transferring files. Please try again later or contact support if the issue persits",
      "keepSessionOpen":"Please do not exit or refresh your session or everyone will have to start over. Ensure that the screen stays on this page. If there are any issues try the file transfer over again,try a different platform or contact support if the issue persists",
      "errorOnRecevingRequest":"Someone tried to send you a file transfer request but an error occured. Please try again later or contact support if the issue persists"
    }
  },
  "TransferRecipientsFormZero": {
    "title":"Send Files Transfer Request",
    "subtitle":"Provide the email of the person you would like to send files to. Remember the person must have an account, added you as a friend and have turned on receive files in order to receive your request. There is no limitation on file sizes howevers if you encounter an issue try to use a different platform (mobile web -> mobile app -> desktop web vice versa). Only firefox (send,receive)are unusable at this time. For ios devices you can only receive on mobile and send on web. We submitted a report to apple and hopefully it will resolved soon but hey you can send and receive on your iphone!",
    "mainForm": {
      "recipientsField": {
        "label": "Recipients",
        "error": {}
      }
    },
    "WMLNotifyOne":{
      "transferFilesJobsAreRunning":"Only one transfer file job can run at a time"
    }
  },
  "FriendControlZero": {
    "mainForm": {
      "emailField": {
        "label": "Email",
        "error": {}
      }
    },
    "friendForm": {
      "receiveFilesField": {
        "label": "receive files",
        "error": {}
      }
    }
  },
  "GuideZero":{
    "contents":[
      "Navigating the Interface",
      "Understanding Your Profile",
      "Purchases and Subscriptions",
      "The Video Dashboard",
      "The Data Editor",
      "Downloading Videos",
      "File Transfer"
    ]
  },
  "VerifyEmailZero":{
    "title":"Verify Email",
    "message":"An email verifcation was sent to your account. You must verify your email before signing in. Once verified reload this page",
    "signOutBtn":"Sign Out"
  }
}
