<nav [class]="classPrefix('MainPod')">
  <section [class]="classPrefix('Pod0')">
    <logo-img-zero
    [class]="classPrefix('Pod0Img0')"
    ></logo-img-zero>
    <h1 [class]="classPrefix('Pod0Title0')">{{"global.title" | translate}}</h1>
  </section>
  <ul [class]="classPrefix('Pod1')">
    <li
    *ngFor="let route of navService.desktopNavOne; let i = index;">
      <a
      *ngIf="4 !== i"
      [routerLink]="route.options[0].routerLink"
      [class]="classPrefix('Pod1Link0')">{{route.options[0].text | translate}}</a>
      <a
      *ngIf="4 === i"
      (click)="route.options[0].click()"
      [class]="classPrefix('Pod1Link0')">{{route.options[0].text | translate}}</a>
    </li>
  </ul>
  <section [class]="classPrefix('Pod2')">
    <i
    (click)="navService.mobileNavOne.open()"
    [class]="classPrefix('Pod2Icon0') + ' fa-solid fa-bars'"></i>
  </section>
</nav>
